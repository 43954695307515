import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userDevice: 0,
		language: 'ko',
	},
	getters: {
		userDevice: state => state.userDevice,
		language: state => state.language,
	},
	mutations: {
		setUserDevice(state, userDevice) {
			state.userDevice = userDevice
		},
		setLanguage(state, language) {
			state.language = language
		},
	},
})

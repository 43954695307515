<template>
	<div class="grey lighten-3 pt-10 pt-sm-16 pb-10">
		<div style="max-width: 1200px" class="px-4 px-sm-0 mx-auto">
			<div class="d-flex align-center mb-7">
				<img src="@/assets/images/logo.png" alt="" />
				<v-spacer></v-spacer>
				<a href="policy" class="menu">이용약관</a>
				<a href="privacy" class="menu">개인정보처리방침</a>
			</div>
			<div class="tella-info mb-7">
				(주) 텔라<br />
				대표이사 진유하 | 사업자등록번호: 114-87-15235
				<br class="d-block d-sm-none" />
				통신판매업신고번호: 제2019-서울강남-02907호<br />
				원격평생교육시설: 제 원-594호 (텔라원격평생교육원)
				<br class="d-block d-sm-none" />
				주소: 서울시 강남구 테헤란로 151, 415호
				<br />
				대표 이메일: tella@tella.co.kr | 고객센터: 070-5118-9565
				<br class="d-block d-sm-none" />
				평일 10:00 - 17:00 | 점심 12:00 - 13:00 | 주말·공휴일 휴무<br />
			</div>
			<div class="d-flex align-center pb-sm-12">
				<a
					v-if="language === 'ja'"
					href="https://www.instagram.com/tellajp/"
					target="_blank"
					class="mr-2"
				>
					<img src="@/assets/images/insta-icon-circle.png" alt="" />
				</a>
				<a
					v-if="language === 'ja'"
					href="https://twitter.com/TellaJP/"
					@click="gtagEvent('click_SNS_insta')"
					target="_blank"
					class="mr-2"
				>
					<img
						style="width: 34px"
						src="@/assets/images/twitter-icon-circle.png"
						alt=""
					/>
				</a>
				<a
					v-if="language === 'ko'"
					href="https://www.instagram.com/tellakor/"
					@click="gtagEvent('click_SNS_insta')"
					target="_blank"
					class="mr-2"
				>
					<img src="@/assets/images/insta-icon-circle.png" alt="" />
				</a>
				<a
					v-if="language === 'ko'"
					@click="gtagEvent('click_SNS_youtube')"
					href="https://www.youtube.com/channel/UCXu1Dm9ocPEnu7B_Fic5HjA"
					target="_blank"
					class="mr-2"
				>
					<img src="@/assets/images/youtube-icon-circle.png" alt="" />
				</a>
				<a
					v-if="language === 'ko'"
					@click="gtagEvent('click_SNS_blog')"
					href="https://m.blog.naver.com/tellakor"
					target="_blank"
					class="mr-2"
				>
					<img src="@/assets/images/naver-icon-circle.png" alt="" />
				</a>
				<a
					v-if="language === 'ko'"
					@click="gtagEvent('click_SNS_fb')"
					href="https://www.facebook.com/TELLAKor"
					target="_blank"
					class="mr-2"
				>
					<img src="@/assets/images/facebook-icon-circle.png" alt="" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		language() {
			return this.$store.getters['language']
		},
	},
	methods: {
		gtagEvent(eventName) {
			// eslint-disable-next-line no-undef
			gtag('event', eventName)
		},
	},
}
</script>

<style lang="scss" scoped>
.menu {
	margin: 0 10px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: -0.7px;
	text-align: center;
	color: #1a1a1a;
	text-decoration: unset;
}

.tella-info {
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.83;
	letter-spacing: -0.2px;
	color: #888;
}
</style>

import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import numberFormat from './plugins/number-format'
import DefaultLayout from './components/layout/Default'
import EmptyLayout from './components/layout/Empty'
import OnlyBar from './components/layout/OnlyBar'
import MobileEmptyDesktopDefaultLayout from './components/layout/MobileEmptyDesktopDefault'

Vue.use(numberFormat)

Vue.config.productionTip = false

Vue.component('default', DefaultLayout)
Vue.component('empty', EmptyLayout)
Vue.component('mobileEmptyDesktopDefault', MobileEmptyDesktopDefaultLayout)
Vue.component('onlyBar', OnlyBar)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')

<template>
	<v-app id="web-home">
		<component :is="layout">
			<router-view></router-view>
		</component>
		<!-- <AppBar />
		<v-main id="tella-web" :style="`padding-top: ${this.barHeight}px`">
			<router-view />
		</v-main>
		<Footer /> -->
		<!-- <BottomSheet /> -->
	</v-app>
</template>

<script>
// import BottomSheet from './components/BottomSheet'

export default {
	name: 'App',
	computed: {
		layout() {
			return this.$route.meta.layout
		},
		routePath() {
			return this.$route.path
		},
	},
	created() {
		this.detectDevice()
	},
	watch: {
		routePath() {
			// eslint-disable-next-line no-undef
			amplitude
				.getInstance()
				.logEvent(`view_tella${this.$route.name}`, this.$route.params)
		},
	},
	methods: {
		detectDevice() {
			;/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			)
				? this.$store.commit('setUserDevice', 'mobile')
				: this.$store.commit('setUserDevice', 'desktop')
		},
	},
}
</script>

<style lang="scss">
@import url('./assets/css/main.css');
/* @import url('./assets/css/color.scss'); */
@import url('./assets/css/typography.css');
@import url('./assets/css/button.css');
@import url('./assets/css/form.css');

@media screen and (min-width: 600px) {
	#web-home {
		min-width: 900px;
	}
}
.v-navigation-drawer {
	z-index: 10000001 !important;
}
.sub--title {
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.9px;
}
.main--title {
	font-size: 30px;
	font-weight: normal;
	letter-spacing: -1.5px;
	color: #1a1a1a;
}

@media screen and (max-width: 600px) {
	.main--title {
		font-size: 28px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.4px;
		text-align: center;
		color: #1a1a1a;
	}
}

.drwaer-button {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.571429px;
	color: #1a1a1a;
}
.bar-button {
	padding: 0 15px;
	margin: 0 10px;
	text-decoration: unset;

	span {
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		/* or 131% */

		display: flex;
		letter-spacing: -0.571429px;

		color: #1a1a1a;
	}
}
</style>

<style scoped lang="scss">
.tooltip {
	position: absolute;
	z-index: 10;
	color: #fff;
	line-height: 1.1em;
	background: #353535;
	border-radius: 10px;
	width: 110px;
	height: 30px;
	font-weight: 700;
	font-size: 12px;
	padding-top: 10px;
	font-weight: bold;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		top: -5px;
		left: 50%;
		margin-left: -5px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;

		border-bottom: 10px solid #353535;
	}
}
</style>

<template>
	<div>
		<!-- <Nav /> -->
		<AppBar />
		<v-main id="tella-web" class="pt-sm-14 pt-0">
			<router-view />
		</v-main>
	</div>
</template>

<script>
import AppBar from '@/components/layout/AppBar'

export default {
	name: 'DefaultLayout',
	components: {
		AppBar,
		// BottomSheet,
	},
	data() {
		return {
			offsetTop: 0,
			drawer: false,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['userDevice']
		},
		barHeight() {
			return this.userDevice !== 'mobile' ? 70 : 0
		},
	},
}
</script>

<style scoped></style>

<template>
	<div>
		<!-- <Nav /> -->
		<AppBar />
		<v-main id="tella-web" :style="`padding-top: ${this.barHeight}px`">
			<router-view />
		</v-main>
		<Footer />
	</div>
</template>

<script>
import AppBar from '@/components/layout/AppBar'
import Footer from '@/components/layout/Footer.vue'

export default {
	name: 'DefaultLayout',
	components: {
		AppBar,
		Footer,
		// BottomSheet,
	},
	data() {
		return {
			offsetTop: 0,
			drawer: false,
			windowSize: {
				x: 0,
				y: 0,
			},
			mobileActiveMenu: null,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['userDevice']
		},
		barHeight() {
			return this.userDevice === 'mobile' ? 55 : 70
		},
		tooltipStyle() {
			const leftPosition =
				this.windowSize.x <= 900
					? 'right: 15px;'
					: 'left: 50%;	margin-left:330px;'
			const topPosition =
				this.userDevice === 'mobile' ? 'top: 55px;' : 'top: 60px;'
			return leftPosition + topPosition
		},
	},
	mounted() {
		this.onResize()
	},

	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		scrollTo(selector) {
			this.$vuetify.goTo(selector, {
				offset: this.barHeight,
			})
		},
		onScroll() {
			this.offsetTop = document.querySelector('html').scrollTop
			let whyTellaEl = document.querySelector('#dont-give-up').offsetTop
			let youCanDoItEl = document.querySelector('#you-can-do-it').offsetTop
			let price = document.querySelector('#price').offsetTop

			this.mobileActiveMenu =
				price < this.offsetTop
					? 'price'
					: youCanDoItEl <= this.offsetTop
					? 'you-can-do-it'
					: whyTellaEl <= this.offsetTop
					? 'dont-give-up'
					: null
		},
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight }
		},
	},
}
</script>

<style scoped></style>

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Home.vue'),
	},
	{
		path: '/policy',
		name: 'Policy',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Policy.vue'),
	},
	{
		path: '/privacy',
		name: 'Privacy',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
	},
	{
		path: '/tutor',
		name: 'Tutor',
		meta: { layout: 'empty' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Tutor.vue'),
	},
	{
		path: '/price',
		name: 'Price',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Price.vue'),
	},
	{
		name: 'Program',
		path: '/programs',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/program/Index.vue'),
	},
	{
		name: 'ProgramCategory',
		path: '/programs/:category',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "about" */ '../views/program-category/Index.vue'
			),
	},
	{
		name: 'ProgramDetail',
		path: '/programs-detail/:programId',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "about" */ '../views/program-detail/Index.vue'
			),
	},
	{
		name: 'Review',
		path: '/reviews',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/review/Index.vue'),
	},
	{
		path: '/myhome',
		redirect: () => {
			window.location.href = 'https://my.tella.co.kr'
		},
	},
	{
		path: '/myhome/*',
		redirect: () => {
			window.location.href = 'https://my.tella.co.kr'
		},
	},
	{
		path: '/event',
		name: 'Event',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "event" */ '../views/event/Index.vue'),
	},
	{
		path: '/event/2022/from-today',
		name: 'Event2022FromToday',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/FromToday.vue'
			),
	},
	{
		path: '/event/2024/from-today/business',
		name: 'Event2022CouponPack',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/CouponPack.vue'
			),
	},
	{
		path: '/event/2024/from-today/business',
		name: 'Event2023FromTodayBusiness',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/FromTodayBusiness.vue'
			),
	},
	{
		path: '/event/2024/from-today/basic',
		name: 'Event2023FromTodayBasic',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/FromTodayBasic.vue'
			),
	},
	{
		path: '/event/2022/coupon-pack',
		name: 'Event2023CouponPack',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/CouponPack.vue'
			),
	},
	{
		path: '/event/friend-recommend',
		name: 'EventFriendRecommend',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/FriendRecommend.vue'
			),
	},
	{
		path: '/event/reveiw',
		name: 'EventReview',
		meta: { layout: 'mobileEmptyDesktopDefault' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/Review.vue'
			),
	},
	{
		path: '/b2b',
		name: 'B2B',
		meta: { layout: 'empty' },
		component: () => import(/* webpackChunkName: "B2B" */ '../views/B2B.vue'),
	},
	{
		path: '/*',
		redirect: '/',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

export default router
